<template>
  <div>
    <v-toolbar
      dense
      class="elevation-5 theme--light white--text"
      :color="!$wiDark ? 'primary' : 'dark'"
    >
      <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <WiListFilters
        v-if="filtersConfig"
        :config="filtersConfig"
        :params="filters"
        @filter="filters => { onFilter(filters) }"
      ></WiListFilters>
      <GoToDateDialog
        :loading="loading"
        :events="events"
        @select="goToDate"
      ></GoToDateDialog>
      <v-btn outline small color="white" @click="addEvent()">
        <v-icon>add</v-icon>
        <span>
          {{ addButtonText }}
        </span>
      </v-btn>
      <v-btn icon ripple @click="refreshCalendar()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      style="margin: 1px 0 0 0 !important;"
    ></v-progress-linear>
    <v-card style="margin: 10px; padding: 20px;">
      <FullCalendar
        ref="calendar"
        :options="calendarOptions"
      />
    </v-card>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import GoToDateDialog from './WNCalendarGoToDateDialog'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'
import WiListFilters from '@/default/component/WiList/Filters'

export default {
  name: 'WNCalendar',
  data() {
    return {
      events: [],
      loading: false,
      calendarOptions: {
        height: window.innerHeight - 175,
        locales: [ptBrLocale],
        timeZone: 'local',
        firstDay: 0,
        dayMaxEventRows: 4,
        nowIndicator: true,
        editable: true,
        defaultTimedEventDuration: '00:30',
        plugins: [ listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin ],
        initialView: this.defaultCalendarView, // dayGridWeek, timeGridWeek, dayGridMonth, listDay, listWeek, listMonth, listYear
        businessHours: [
          {
            daysOfWeek: [ 1 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 2 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 3 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 4 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 5 ], 
            startTime: '08:00',
            endTime: '17:00'
          }
        ],
        headerToolbar: {
          center: 'title',
          left: 'timeGridDay, timeGridFourDay, timeGridWeek, dayGridMonth' // buttons for switching between views
        },
        views: {
          timeGridFourDay: {
            type: 'timeGridWeek',
            duration: { days: 4 },
            buttonText: '4 dias'
          }
        },
        events: this.getEvents,
        dateClick: this.dayClick,
        loading: this.setLoading,
        eventClick: this.eventClick,
        eventDrop: this.eventDrop,
        eventContent: (arg) => this.getEventContent(arg),
      }
    }
  },
  methods: {
    getEvents: function ( fetchInfo, successCallback, errorCallback ) {
      this.$emit('getEvents', {
        fetchInfo,
        successCallback,
        errorCallback,
        refresh: this.refreshCalendar,
        setEvents: this.setEvents
      })
    },
    setEvents: function (events) {
      this.events = events
    },
    setLoading: function ( isLoading ) {
      this.loading = isLoading
      if (isLoading) {
        this.$swal({
          title: 'Buscando agendamentos...',
          text: 'Aguarde até que a ação seja finalizada.',
          onOpen: () => { this.$swal.showLoading() }
        })
      } else {
        this.$swal.close()
      }
    },
    getEventById: function (eventId) {
      return this.events.find(event => (event.id == eventId))
    },
    eventClick: function (event) {
      const eventSelected = this.getEventById(event.event.id)
      this.$emit('eventClick', eventSelected)
    },
    eventDrop: function (event) {
      this.$swal({
        title: 'Deseja reagendar?',
        text: 'Esta ação não poderá ser desfeita!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, reagendar!',
        cancelButtonText: 'Cancelar'
      })
      .then((response) => {
        if (response.value) {
          const eventSelected = this.getEventById(event.event.id)
          this.$emit('eventDrop', {
            event: eventSelected,
            newDate: event.event.startStr
          })
        } else {
          this.refreshCalendar()
        }
      })
    },
    dayClick: function (day) {
      this.$emit('dateClick', day)
    },
    addEvent: function () {
      this.$emit('addEvent')
    },
    refreshCalendar: function () {
      this.$refs?.calendar?.getApi()?.refetchEvents()
    },
    goToDate: function (newDate) {
      this.$refs?.calendar?.getApi()?.changeView('dayGridMonth', newDate);
    },
    onFilter: function (filters) {
      this.$emit('onFilter', filters)
      this.$refs?.calendar?.getApi()?.refetchEvents()
    },
    getEventContent(arg) {
      if (this.eventContentFormatter) {
        return this.eventContentFormatter(arg);
      }

      return undefined;
    }
  },
  components: {
    FullCalendar,
    WiListFilters,
    GoToDateDialog
  },
  props: {
    title: {
      type: String,
      default: 'Agenda'
    },
    addButtonText: {
      type: String,
      default: 'Adicionar Agendamento'
    },
    defaultCalendarView: {
      type: String,
      default: 'timeGridWeek',
      validator: function (value) {
        return ['dayGridWeek', 'timeGridWeek', 'dayGridMonth', 'listDay', 'listWeek', 'listMonth', 'listYear'].includes(value)
      }
    },
    filtersConfig: {
      type: Object,
      default: null
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    eventContentFormatter: {
      type: Function,
      default: null
    }
  }
}
</script>
<style>
  .fc .fc-button .fc-icon {
    margin-top: -10px !important;
  }



</style>